:root {
  --client-color: #d30000;
  --client-hover-color: #ba0101;
  --client-app-bgcolor: #fafafa;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: #4a4949;
  background-color: var(--client-app-bgcolor);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700 !important;
}

.border-Active {
  border-color: var(--client-color);
  border-width: 3px;
  outline: none;
  border-style: solid;
}

.border-Default {
  border-color: transparent;
  border-width: 3px;
  outline: none;
  border-style: solid;
}

.radio-Default {
  border-color: gray;
  border-width: 3px;
  outline: none;
  border-style: solid;
}

.radio-Active {
  border-color: var(--client-color);
  border-width: 3px;
  background-color: var(--client-color);
  outline: none;
  border-style: solid;
}

.btn-visited {
  opacity: 0.6;
  background-color: var(--client-color);
}

.btn-active {
  opacity: 1;
  background-color: var(--client-color);
}

.btn-deactive {
  opacity: 1;
  background-color: lightgray;
}

.bg-lightgray {
  opacity: 1;
  background-color: lightgray;
}

.disabled-link {
  pointer-events: none;
}

.opacity-0 {
  opacity: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.h-40 {
  height: 8rem;
}
.w-33 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

@media (max-width: 576px) {
  .w-52 {
    width: 80% !important;
  }
  .flex-1 {
    flex: 1 1 auto !important;
  }
  .w-text-area {
    width: 22rem !important;
  }
  .h-52 {
    height: 10rem !important;
  }
}

@media (min-width: 577px) {
  .flex-1 {
    flex: 1 1 0 !important;
  }
}

.w-52 {
  width: 13rem;
}

.h-52 {
  width: 80%;
}

.w-text-area {
  width: 30rem;
}

.relative {
  position: relative;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-col {
  flex-direction: column;
}
.absolute {
  position: absolute;
}
.block {
  display: block;
}

.position-top {
  top: 0;
}

button {
  display: inline-block;
  background-color: transparent;
  border: none;
}

.checkbox {
  display: inline-block;
  width: 20px;
  height: 18px;
}

.btn:hover {
  outline: none;
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.btn {
  box-shadow: -2px -6px 10px -3px rgba(0, 0, 0, 0.1);
}

.btn_img_small {
  width: auto;
  height: 150px;
}

.btn_img_medium {
  width: auto;
  height: 200px;
}

.custom-width-behavior {
  width: 100%;
}

.item1 {
  grid-column: 4 / 6;
}

.nav_btn {
  padding: 2vw;
  margin: 0px;
  font-size: 22px;
}

.desc-lh {
  line-height: 1.2;
}

.preHeadline {
  color: lightgrey;
}

.btn-primary {
  background-color: var(--client-color);
  border-color: var(--client-color);
}

.btn-primary:hover,
.btn-primary-focus,
.btn-primary:focus {
  background-color: var(--client-hover-color);
  border-color: var(--client-hover-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--client-hover-color);
  border-color: var(--client-hover-color);
}

.btn-outline-primary {
  border-color: var(--client-color);
  color: var(--client-color);
}

.btn-outline-primary:hover,
.btn-outline-primary-focus,
.btn-outline-primary:focus {
  background-color: var(--client-color);
  border-color: var(--client-color);
  color: white;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--client-color);
  border-color: var(--client-color);
  color: white;
}
